
// Be cautious when editing these. They are used in the fronted+backend.
export enum DemoType {
    BULLETS_TO_SHORTFORM = "BULLETS_TO_SHORTFORM",
    BULLETS_TO_LONGFORM = "BULLETS_TO_LONGFORM",
    BUZZWORDS_TO_TAGLINE = "BUZZWORDS_TO_TAGLINE",
    FOOD_DESCRIPTORS = "FOOD_DESCRIPTORS",
    STYLE_TRANSFORM = "STYLE_TRANSFORM"
}

export interface DemoDetails {
    demoType: DemoType
    demoDisplayDescription: string
    inputHelperText: string
    navTitle: string
}


export const DEMO_BULLETS_TO_SHORTFORM: DemoDetails = {
    demoType: DemoType.BULLETS_TO_SHORTFORM,
    demoDisplayDescription: "Enter a few bullet points, and we'll convert it into an Instagram caption for your brand.",
    inputHelperText: "eg: \"Whiskey. Weekend. Deserve. Luxury.\"",
    navTitle: "Bullet points → Instagram Captions"
}

export const DEMO_BULLETS_TO_LONGFORM: DemoDetails = {
    demoType: DemoType.BULLETS_TO_LONGFORM,
    demoDisplayDescription: "Give us a few bullet points, and we'll instantly convert it into long form text (like blog posts).",
    inputHelperText: "eg: Evening walk is good for you. It works your legs. Your heart benefits. Good for soul.",
    navTitle: "Bullet points → Long Form Content"
}

export const DEMO_FOOD_DESCRIPTIONS: DemoDetails = {
    demoType: DemoType.FOOD_DESCRIPTORS,
    demoDisplayDescription: "Give us some cues about the food, and we'll give you a description you can add to a restaurant menu.",
    inputHelperText: "Example:\"Adraki Champ. Lamb chops. Ginger. Creamy cumin marinate.\"",
    navTitle: "Custom Client Ask: Food descriptors"
}

export const DEMO_STYLE_TRANSFORM: DemoDetails = {
    demoType: DemoType.STYLE_TRANSFORM,
    demoDisplayDescription: "Enter some text, and we'll rephrase it to be more playful.",
    inputHelperText: "Example: \"Crafty. Intelligent AI tool. Generates text from minimal context. Very cheap.\"",
    navTitle: "Custom Client Ask: Playful Style Transform",
}

export const DEMO_BUZZWORDS_TO_TAGLINE: DemoDetails = {
    demoType: DemoType.BUZZWORDS_TO_TAGLINE,
    demoDisplayDescription: "Enter some buzzwords, and we'll create some brand tagline ideas",
    inputHelperText: "Example: \"Premium. Notebooks.\"",
    navTitle: "Buzzwords → Tagline",
}

export const demoList = [DEMO_BULLETS_TO_SHORTFORM, DEMO_BULLETS_TO_LONGFORM, DEMO_BUZZWORDS_TO_TAGLINE, DEMO_FOOD_DESCRIPTIONS, DEMO_STYLE_TRANSFORM]

export const demoTypeToDetailsMap = new Map();
demoTypeToDetailsMap.set(DemoType.BULLETS_TO_SHORTFORM, DEMO_BULLETS_TO_SHORTFORM)
demoTypeToDetailsMap.set(DemoType.BULLETS_TO_LONGFORM, DEMO_BULLETS_TO_LONGFORM)
demoTypeToDetailsMap.set(DemoType.BUZZWORDS_TO_TAGLINE, DEMO_BUZZWORDS_TO_TAGLINE)
demoTypeToDetailsMap.set(DemoType.FOOD_DESCRIPTORS, DEMO_FOOD_DESCRIPTIONS)
demoTypeToDetailsMap.set(DemoType.STYLE_TRANSFORM, DEMO_STYLE_TRANSFORM)