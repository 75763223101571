import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import TextField from '@mui/material/TextField';
import { Alert, AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Select, Snackbar, Toolbar, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { getRewriteOptions } from './utils/network';
import MenuIcon from '@mui/icons-material/Menu';
import KeyIcon from '@mui/icons-material/Key';
import CopyIcon from '@mui/icons-material/ContentCopy'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LoadingButton from '@mui/lab/LoadingButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DemoDetails, demoList, DemoType, demoTypeToDetailsMap, DEMO_BULLETS_TO_LONGFORM, DEMO_BULLETS_TO_SHORTFORM } from './utils/demo-types';
import { writeToClipboard } from './utils/web-utils';

function App() {
  const localLicenseKey = localStorage.getItem('licenseKey')
  const [licenseKeyInput, setLicenseKeyInput] = useState("")
  const [editLicenseKey, setEditLicenseKey] = useState(localLicenseKey === "" || localLicenseKey == null)
  const [inputText, setInputText] = useState("")
  const [rewriteOptions, setRewriteOptions] = useState<string[]>([])
  const [currentDemo, setCurrentDemo] = useState<DemoDetails>(DEMO_BULLETS_TO_LONGFORM)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [ideasLoading, setIdeasLoading] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const [errorDetails, setErrorDetails] = useState("")

  const handleLicenseKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLicenseKeyInput(event.target.value);
  };

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={e => setDrawerOpen(true)}
          >
          <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            CraftyAI<sup> demo</sup>
          </Typography>
          <Button endIcon={<KeyIcon />}
          color="inherit"
          variant="text"
          onClick={e => setEditLicenseKey(true)}>
            Update Access Key
          </Button>
        </Toolbar>
      </AppBar>

      

      <Drawer 
        anchor="left"
        open={drawerOpen}
        onClose={(e, reason)=> setDrawerOpen(false)}
      >
        <div className="DrawerToolbar">
        
          <IconButton onClick={e => setDrawerOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {demoList.map(d => (
            <ListItem key={d.demoType}>
              <ListItemButton onClick={e => {setCurrentDemo(d)
                setDrawerOpen(false)
              }}>
                <ListItemText primary={d.navTitle} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <div className="Content" style={{paddingTop:"4em"}}>
        <Typography variant="h6" component="div">{currentDemo.navTitle}</Typography>
        <div style={{marginTop:"1em", color:"#777"}}>{currentDemo.demoDisplayDescription}</div>
        <TextField id="outlined-basic" 
          className='inputText'
          label="Input Text" 
          helperText={currentDemo.inputHelperText}
          variant="outlined"  
          fullWidth={true} 
          sx={{mt:6}}
          multiline={true}
          onChange={e => setInputText(e.target.value)}/>

        <LoadingButton 
          variant="contained" 
          className='rewrite-button'
          size='medium'
          sx={{
            mt:4,
          }}
          loading={ideasLoading}
          loadingPosition="end"
          endIcon={<RocketLaunchIcon />}
          onClick={()=>{
              let licenseKey = localStorage.getItem('licenseKey')
              if(licenseKey == null || licenseKey === "") {
                setErrorDetails('Please Update your Access Key')
                setDisplayError(true)
                setEditLicenseKey(true)
                return
              }

              if (inputText == null || inputText.length==0) {
                // don't make requests for empty inputs.
                return
              }

              setIdeasLoading(true)
              getRewriteOptions(inputText, currentDemo.demoType.toString(), licenseKey as string)
                .then(rewriteOptions => {
                  // console.log('setting')
                  // console.log(typeof rewriteOptions)
                  // console.log(rewriteOptions)
                  setIdeasLoading(false)
                  setRewriteOptions(rewriteOptions)
                })
                .catch(e => {
                  setIdeasLoading(false)
                  setErrorDetails(getFriendlyError(e))
                  setDisplayError(true)
                  console.log(e)
                })
            }}>
            Generate Ideas
        </LoadingButton>

        <div className='rewrite-container' style={{marginTop:"2em"}}>
          {showRewriteOptions(rewriteOptions)}
        </div>
      </div>

      {/* Change Key  */}
      <Dialog open={editLicenseKey} onClose={e => {
          setEditLicenseKey(false)
          setLicenseKeyInput("")
        }}
        fullWidth={true}
        maxWidth="sm"

      >
        <DialogTitle>Update Your Access Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
             <br/>
            {showCurrentKey()}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="lKey"
            label="Access Key"
            type="password"
            fullWidth
            variant="outlined"
            value={licenseKeyInput}
            onChange={handleLicenseKeyChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={e => {
          setEditLicenseKey(false)
          setLicenseKeyInput("")}}>
            Cancel
          </Button>
          <Button onClick={e => {
            localStorage.setItem('licenseKey', licenseKeyInput)
            setEditLicenseKey(false)}}>
              Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Display Errors */}
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={displayError}
        autoHideDuration={4000}
        onClose={e => setDisplayError(false)}
      >
        <Alert onClose={e => setDisplayError(false)} severity="error" sx={{ width: '100%' }}>
          {errorDetails}
        </Alert>
      </Snackbar>
    </div>
  );
}

function getFriendlyError(e: string) {
  if(e === "Forbidden") {
    return "Uh oh! You don't have access to Crafty. Please update your Access Key!"
  }

  if(e === "Too Many Requests") {
    return "Woah there slow down! We have rate limited you."
  }

  return e
}

function showCurrentKey() {
  let key = localStorage.getItem('licenseKey')
  let keyAvailable = `Access Key currently stored in this browser is: ${key}`
  let keyUnavailable = "Looks like you're missing an Access Key in this browser. Enter the access key we provided to you."
  return key == null || key === "" ? keyUnavailable : keyAvailable
}
function showRewriteOptions(rewriteOptions: string[]) {
  // console.log('showing')
  // console.log(typeof rewriteOptions)
  // console.log(rewriteOptions)
  return rewriteOptions.map(option => {
    return <div style={{position:"relative"}}>
      <div style={{position:"absolute", height: "100%", top:0, right:0, marginTop:"-0.8em", marginRight:"-0.8em"}}>
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label="menu"
          className='copy-button'
          onClick={e => {
            writeToClipboard(option)
          }}
        >
          <CopyIcon />
        </IconButton>
      </div>
      <div style={{whiteSpace:"pre-line"}}>
       {option.trim()} 
      </div>
      
     </div>
  })
}

export default App;
