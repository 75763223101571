
var requestHeaders = new Headers();
requestHeaders.append("Content-Type", "application/json");

export async function getRewriteOptions(inputText: string, demoType: string, licenseKey: string): Promise<string[]> {
    let raw = JSON.stringify({
        inputText: inputText,
        demoType: demoType,
        licenseKey: licenseKey 
    });
    let requestOptions = {
        method: 'POST',
        headers: requestHeaders,
        body: raw,
      };

    return fetch("https://secure-granite-351914.uc.r.appspot.com/rewritePrototype", requestOptions)
    // return fetch("http://localhost:3000/rewritePrototype", requestOptions)
      .then(response => {
        if(!response.ok) {
            throw response.statusText
        } 
        return response.text()
      }).then(response => {
            let parsed = JSON.parse(response) as string[]
            return parsed
      })
}
